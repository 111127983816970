<template>
  <main >
    <div v-if="!product" class="spinner_container" >
      <Spinner></Spinner>
    </div>
    <div v-else>
      <Breadcrumb :product="product"/>
      <div class="product__wrapper">
        <section class="product">
          <article class="product__meta">
            <div class="product__meta__images">
              <div class="product__meta__images__wrapper selected"
                   v-if="product.productimage_set.length === 0">
                <img v-lazy="product.image" :srcset="product.srcset"
                     :sizes="$store.state.sizes" :alt="`${product.name} photography`">
              </div>
              <div class="product__meta__images__wrapper" v-else
                   v-for="(image, index) in product.productimage_set.filter(e=>!e.supplementary)"
                   :class="{'selected': index === 0}"
                   @click.capture="selectImg($event)">
                <img v-lazy="image.url" :srcset="image.srcset"
                     :sizes="$store.state.sizes" :alt="`${product.name} photography`">
              </div>
            </div>
            <div class="product__meta__text">
              <h2>{{ product.name }}</h2>
              <ul class="product__meta__text-tags">
                <li v-for="c in product.categs">{{ c.name }}</li>
              </ul>
              <div class="product__meta__text-description"
                   v-for="feature in product.ecommercetemplatefeatureline_set"
                   v-if="feature.heading===1">
                <h3>{{ feature.feature.name }}</h3>
                <p v-for="value in feature.value_ids">{{ value.text }}</p>
              </div>
            </div>
            <aside v-if="getLocation" class="product__meta__form">
              <form class="cart-form" @submit.prevent="addToCart">
                <div class="cart-form__row">
                  <!-- TODO v-if / else-->
                  <p class="is-available">
                    <span class="available">Produit disponible</span>
                    <!-- <span class="not-available">Produit non disponible</span>-->
                  </p>
                </div>
                <div class="cart-form__row"
                     v-if="product.productproduct_set.length !== 1"
                     v-for="(attribute, index) in product.attribute" :key="index">
                  <label :for="attribute.value" v-if="attribute">{{ attribute.value }}</label>
                  <div class="select-wrapper">
                    <select :ref="'attribute-'+index" :id="'attribute-' + index"
                            :name="attribute.value" :aria-label="'Select ' + attribute.value"
                            v-model="form.selected[index]"
                            @change="changeNext(index)"
                            required>
                      <option v-for="attributeValue in getAttributeValue(attribute.res_id)"
                              :value="attributeValue.id">
                        {{ attributeValue.value }}
                      </option>
                    </select>
                    <span></span>
                  </div>
                </div>
                <div class="cart-form__row">
                  <label :for="`${product.name}-quantity`">Quantité</label>
                  <div class="quantity">
                    <button aria-label="remove" type="button"
                            @click="quantity > (id === 'couteau' ? 3 : 1) ? (id === 'couteau' ? quantity -= 3 : quantity--)
                           : quantity = id === 'couteau' ? 3 : 1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                           viewBox="0 0 24 24">
                        <path d="M5 11h14v2H5z"></path>
                      </svg>
                    </button>
                    <input type="number" v-model="quantity" :id="`${product.name}-quantity`" :min="id === 'couteau' ? 3 : 1"
                           :step="id === 'couteau' ? 3 : 1"
                           :style="{width: `${quantity.toString().length}ch`}"/>
                    <span v-if="product.categs[0].id===5 && quantity>1"> pièces</span>
                    <span v-if="product.categs[0].id===5 && quantity==1"> pièce</span>
                    <span v-if="product.categs[0].id===8"> kg</span>
                    <button aria-label="add" type="button" @click="id === 'couteau' ? quantity += 3 : quantity++">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                           viewBox="0 0 24 24">
                        <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="cart-form__row">
                  <div class="cart-resume">
                    <div class="total-price">
                      <p>{{
                          price.price_it ? String((price.price_it * quantity).toFixed(2)) + ' €' : "Indiquez nous votre localisation"
                        }}</p>
                    </div>
                    <button aria-label="add" type="submit" class="button full width">Ajouter au panier
                    </button>
                  </div>
                </div>
              </form>
            </aside>
            <aside v-else>
              <button aria-label="locate" class="button " @click="selectLocation">
                <img alt="delivery" v-lazy="deliveryImg">
                <span
                    v-if="!getLocation || getLocation.city===null || getLocation.city==='null'">
								{{ $t('find_out_more') }}
							</span>
              </button>
            </aside>
          </article>
        </section>

        <section v-if="getProductsRelatedList && getProductsRelatedList.length" class="related-products">
          <div class="section-title">
            <h2>Fréquemment acheté avec ce produit</h2>
            <p>Associez ce produit à notre sélection</p>
          </div>
          <ProductSlider :products="getProductsRelatedList"/>
        </section>

        <section class="product-details" v-if="product.ecommercetemplatefeatureline_set.find(e => e.heading === 2)">
          <div class="section-title">
            <h2>Informations complémentaires</h2>
            <p>Découvrez les caractéristiques du produit</p>
          </div>
          <div class="product-details__wrapper">
            <div class="product-details__text">
              <ul>
                <li v-if="feature.heading === 2"
                    v-for="feature in product.ecommercetemplatefeatureline_set">
                  <h3>{{ feature.feature.name }}</h3> : <span>{{ feature.value_ids[0].text }}</span>
                </li>
              </ul>
            </div>
            <div class="product-details__img">
              <img v-lazy="getSupplementary" :alt="`${product.name} photography`">
            </div>
          </div>
        </section>

        <!-- TODO Custom fields + repeater Odoo-->
        <!--			<section class="custom-details">-->
        <!--				<div class="section-title">-->
        <!--					<h2>Conserver une huitre</h2>-->
        <!--					<p>Découvrez nos conseils pour conserver une huitre</p>-->
        <!--				</div>-->
        <!--				<div class="custom-details__text">-->
        <!--					<p>-->
        <!--						Lorem ipsum dolor sit amet,<a href="#">test</a>consectetur adipisicing elit. Assumenda, esse-->
        <!--						illum molestiae necessitatibus odio omnis qui sapiente ullam voluptate voluptatem.-->
        <!--						<br>-->
        <!--						<br>-->
        <!--						<b>Durée de conservation</b>-->
        <!--						<br>-->

        <!--						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda beatae cumque deserunt-->
        <!--						fugiat itaque-->
        <!--						maiores neque nisi repellendus sed totam! Accusamus accusantium aliquid commodi eos esse-->
        <!--						excepturi ipsum-->
        <!--						magni maxime nemo nisi numquam quibusdam, ratione sapiente sequi, similique veniam vero.-->
        <!--						<a href="#">Comment conserver ses huitres sur le blog</a>-->
        <!--					</p>-->
        <!--				</div>-->

        <!--			</section>-->

        <!--			<section class="recipe">-->
        <!--				<div class="section-title">-->
        <!--					<h2>Idée recette avec une huitre creuse</h2>-->
        <!--					<p>Essayez nos recettes associées à ce produit</p>-->
        <!--				</div>-->
        <!--				<div class="recipe__wrapper">-->
        <!--					<div class="recipe__text">-->
        <!--						<h3>L'huitre Ostra Regal au caviar, recette évidente!</h3>-->

        <!--						<div class="recipe__text__row">-->
        <!--							<h4>Ingrédients : </h4>-->
        <!--							<ul>-->
        <!--								<li>Creuse (Crassostrea gigas)</li>-->
        <!--								<li>Creuse (Crassostrea gigas)</li>-->
        <!--								<li>Creuse (Crassostrea gigas)</li>-->
        <!--							</ul>-->
        <!--						</div>-->

        <!--						<div class="recipe__text__row">-->
        <!--							<h4>Recette : </h4>-->
        <!--							<ol>-->
        <!--								<li>Creuse (Crassostrea gigas)</li>-->
        <!--								<li>Creuse (Crassostrea gigas)</li>-->
        <!--								<li>Creuse (Crassostrea gigas)</li>-->
        <!--							</ol>-->
        <!--						</div>-->

        <!--						<a href="#" class="button full center">Voir la recette</a>-->

        <!--					</div>-->
        <!--					<div class="recipe__img">-->
        <!--						<img src="https://picsum.photos/400/250?random=9" alt="">-->
        <!--					</div>-->
        <!--				</div>-->

        <!--			</section>-->

        <!--			<section class="icons">-->
        <!--				<div class="section-title">-->
        <!--					<h2>Idée recette avec une huitre creuse</h2>-->
        <!--					<p>Essayez nos recettes associées à ce produit</p>-->
        <!--				</div>-->
        <!--				<div class="icons__wrapper">-->
        <!--					<div class="icon">-->
        <!--						<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--							<path fill-rule="evenodd" clip-rule="evenodd"-->
        <!--							      d="M6.36364 0H63.6364C67.1364 0 70 3.15 70 7V49C70 52.85 67.1364 56 63.6364 56H41.3636V63H44.5455C46.2955 63 47.7273 64.575 47.7273 66.5C47.7273 68.425 46.2955 70 44.5455 70H25.4545C23.7045 70 22.2727 68.425 22.2727 66.5C22.2727 64.575 23.7045 63 25.4545 63H28.6364V56H6.36364C2.86364 56 0 52.85 0 49V7C0 3.15 2.86364 0 6.36364 0ZM9.54518 49.0004H60.4543C62.2043 49.0004 63.6361 47.4254 63.6361 45.5004V10.5004C63.6361 8.5754 62.2043 7.0004 60.4543 7.0004H9.54518C7.79518 7.0004 6.36337 8.5754 6.36337 10.5004V45.5004C6.36337 47.4254 7.79518 49.0004 9.54518 49.0004Z"-->
        <!--							      fill="#2E5576"/>-->
        <!--						</svg>-->
        <!--						<h3>Commande en ligne</h3>-->
        <!--						<p>Faites votre choix parmis nos produits de la mer</p>-->
        <!--					</div>-->
        <!--					<div class="icon">-->
        <!--						<svg width="64" height="70" viewBox="0 0 64 70" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--							<path fill-rule="evenodd" clip-rule="evenodd"-->
        <!--							      d="M6.21196 57.2727H6.37105C10.8892 57.2727 14.962 55.0455 18.0165 52.0545C18.6211 51.4818 19.6074 51.4818 20.212 52.0545C23.2347 55.0455 27.3074 57.2727 31.8256 57.2727C36.3438 57.2727 40.4165 55.0455 43.4711 52.0545C44.0756 51.45 45.0301 51.45 45.6665 52.0545C48.6892 55.0455 52.762 57.2727 57.2801 57.2727H57.4392L63.4529 36.0182C63.7074 35.1909 63.6438 34.3 63.262 33.5364C62.8801 32.7727 62.1801 32.2 61.3529 31.9455L57.2801 30.6091V15.9091C57.2801 12.4091 54.4165 9.54545 50.9165 9.54545H41.3711V3.18182C41.3711 1.43182 39.9392 0 38.1892 0H25.462C23.712 0 22.2801 1.43182 22.2801 3.18182V9.54545H12.7347C9.23469 9.54545 6.37105 12.4091 6.37105 15.9091V30.6091L2.2665 31.9455C1.43923 32.2 0.739232 32.7727 0.357414 33.5364C-0.0244045 34.3 -0.119859 35.1909 0.166505 36.0182L6.21196 57.2727ZM57.2807 63.6367C53.1762 63.6367 49.0716 62.3322 45.3807 60.0094C44.8716 59.6913 44.2034 59.6913 43.6944 60.0094C36.3444 64.6867 27.2762 64.6867 19.9262 60.0094C19.4171 59.6913 18.7489 59.6913 18.2398 60.0094C14.5807 62.3322 10.4762 63.6367 6.37163 63.6367H3.18981C1.43981 63.6367 0.00799099 65.0685 0.00799099 66.8185C0.00799099 68.5685 1.43981 70.0004 3.18981 70.0004H6.37163C10.7625 70.0004 15.0898 68.8867 19.0989 66.8504C27.1171 70.9549 36.5353 70.9549 44.5534 66.8504C48.5625 68.9185 52.8898 70.0004 57.2807 70.0004H60.4625C62.2125 70.0004 63.6444 68.5685 63.6444 66.8185C63.6444 65.0685 62.2125 63.6367 60.4625 63.6367H57.2807ZM47.735 15.9092H15.9168C14.1668 15.9092 12.735 17.341 12.735 19.091V28.541L29.8213 22.9092C31.1259 22.4955 32.4941 22.4955 33.7986 22.9092L50.9168 28.541V19.091C50.9168 17.341 49.485 15.9092 47.735 15.9092Z"-->
        <!--							      fill="#2E5576"/>-->
        <!--						</svg>-->
        <!--						<h3>En direct de la mer</h3>-->
        <!--						<p>Faites votre choix parmis nos produits de la mer</p>-->
        <!--					</div>-->
        <!--					<div class="icon">-->
        <!--						<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--							<path-->
        <!--								d="M66.5 31.5H55.405L64.295 22.61C65.66 21.245 65.66 19.04 64.295 17.675C62.93 16.31 60.69 16.31 59.325 17.675L45.5 31.5H38.5V24.5L52.325 10.675C53.69 9.31 53.69 7.07 52.325 5.705C51.6711 5.04962 50.7833 4.6813 49.8575 4.6813C48.9317 4.6813 48.0439 5.04962 47.39 5.705L38.5 14.595V3.5C38.5 1.575 36.925 0 35 0C33.075 0 31.5 1.575 31.5 3.5V14.595L22.61 5.705C21.9561 5.04962 21.0683 4.6813 20.1425 4.6813C19.2167 4.6813 18.3289 5.04962 17.675 5.705C16.31 7.07 16.31 9.31 17.675 10.675L31.5 24.5V31.5H24.5L10.675 17.675C9.31 16.31 7.07 16.31 5.705 17.675C4.34 19.04 4.34 21.245 5.705 22.61L14.595 31.5H3.5C1.575 31.5 0 33.075 0 35C0 36.925 1.575 38.5 3.5 38.5H14.595L5.705 47.39C4.34 48.755 4.34 50.96 5.705 52.325C7.07 53.69 9.31 53.69 10.675 52.325L24.5 38.5H31.5V45.5L17.675 59.325C16.31 60.69 16.31 62.93 17.675 64.295C19.04 65.66 21.245 65.66 22.61 64.295L31.5 55.405V66.5C31.5 68.425 33.075 70 35 70C36.925 70 38.5 68.425 38.5 66.5V55.405L47.39 64.295C48.755 65.66 50.96 65.66 52.325 64.295C53.69 62.93 53.69 60.69 52.325 59.325L38.5 45.5V38.5H45.5L59.325 52.325C60.69 53.69 62.93 53.69 64.295 52.325C65.66 50.96 65.66 48.755 64.295 47.39L55.405 38.5H66.5C68.425 38.5 70 36.925 70 35C70 33.075 68.425 31.5 66.5 31.5Z"-->
        <!--								fill="#2E5576"/>-->
        <!--						</svg>-->
        <!--						<h3>Fraicheur garantie</h3>-->
        <!--						<p>Faites votre choix parmis nos produits de la mer</p>-->
        <!--					</div>-->
        <!--					<div class="icon">-->
        <!--						<svg width="70" height="51" viewBox="0 0 70 51" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--							<path fill-rule="evenodd" clip-rule="evenodd"-->
        <!--							      d="M50.9091 12.7273H58.8636C59.85 12.7273 60.8045 13.2045 61.4091 14L68.7273 23.7682C69.5546 24.8818 70 26.2182 70 27.5864V38.1818C70 39.9318 68.5682 41.3636 66.8182 41.3636H63.6364C63.6364 46.6455 59.3727 50.9091 54.0909 50.9091C48.8091 50.9091 44.5455 46.6455 44.5455 41.3636H25.4545C25.4545 46.6455 21.1909 50.9091 15.9091 50.9091C10.6273 50.9091 6.36364 46.6455 6.36364 41.3636C2.86364 41.3636 0 38.5 0 35V6.36364C0 2.86364 2.86364 0 6.36364 0H44.5455C48.0455 0 50.9091 2.86364 50.9091 6.36364V12.7273ZM12.7271 41.3641C12.7271 43.1141 14.1589 44.5459 15.9089 44.5459C17.6589 44.5459 19.0907 43.1141 19.0907 41.3641C19.0907 39.6141 17.6589 38.1823 15.9089 38.1823C14.1589 38.1823 12.7271 39.6141 12.7271 41.3641ZM58.8639 17.5L65.1003 25.4545H50.9094V17.5H58.8639ZM50.9094 41.3641C50.9094 43.1141 52.3412 44.5459 54.0912 44.5459C55.8412 44.5459 57.273 43.1141 57.273 41.3641C57.273 39.6141 55.8412 38.1823 54.0912 38.1823C52.3412 38.1823 50.9094 39.6141 50.9094 41.3641Z"-->
        <!--							      fill="#2E5576"/>-->
        <!--						</svg>-->
        <!--						<h3>Livraison expresse</h3>-->
        <!--						<p>Faites votre choix parmis nos produits de la mer</p>-->
        <!--					</div>-->

        <!--				</div>-->
        <!--			</section>-->
      </div>
    </div>
  </main>
</template>

<script>
import {getAPI} from "@/services/axios-api";
import {Utils} from "@/services/utils";
import Breadcrumb from "@/components/Breadcrumb";
import ProductCard from "@/components/ProductCard.vue"
import ProductSlider from "@/components/ProductSlider.vue"
import {i18n} from "@/main";
import {mapActions, mapGetters} from "vuex";
import deliveryImg from '@/assets-2/header/delivery.webp'
import Spinner from "@/components/Spinner"

export default {
  name: "Product",
  beforeMount() {
    this.getProductById();
  },
  metaInfo() {
    return {
      title: this.product ? this.product.name : '',
      meta: [
        // meta data to google/edge/fireFox...
        {name: 'title', content: this.product ? this.product.name : ''},
        {name: 'description', content: this.product ? this.product.meta_description : ''},
        {name: 'robots', content: 'index,follow'},
        // to SEO FaceBook & Linkedin
        {property: 'og:type', content: "website"},
        {property: 'og:url', content: "https://lafamilleboutrais.com/"},
        {property: 'og:title', content: this.product ? this.product.name : ''},
        {property: 'og:description', content: this.product ? this.product.meta_description : ''},
        // { property: 'og:image', content: this.getCategory.image },
      ],
    }
  },
  components: {
    ProductCard, Breadcrumb, ProductSlider,Spinner,
  },
  data() {
    return {
      deliveryImg,
      priceItems: [],
      count: 0,
      product: undefined,
      variant: null,
      quantity: 1,
      form: {
        selected: []
      }
    }
  },
  mounted() {
    this.quantity = this.id === 'couteau' ? 3 : 1
  },
  computed: {
    ...mapGetters('pricelist', ['getPricelistItems']),
    ...mapGetters('products', ['getProductsRelatedList']),
    ...mapGetters('auth', ['getLocation']),
    ...mapGetters('category', ['getCategory']),
    price: function () {
      const pricelist = this.$store.getters["pricelist/getPricelistItems"]
      if (this.product.attribute.length === this.form.selected.length && pricelist.length !== 0) {
        let result = this.compareList()
        if (result.length === 1) {
          let item = pricelist.find(e => {
            return e.product.id === result[0].id;
          })
          this.variant = item.product.id
          return item
        } else {
          return "Indiquez votre localisation !"
        }
      } else if (this.product.productproduct_set.length === 1) {
        return pricelist.find(e => {
          return e.product.id === this.product.productproduct_set[0].id;
        })
      }
      return "Indiquez votre localisation !"
    },
    getSupplementary: function () {
      if (this.product) {
        if (this.product.productimage_set.length === 0) {
          return this.product.image
        } else {
          for (let image of this.product.productimage_set) {
            if (image.supplementary) return image.url
          }
          return this.product.image
        }
      }
    }
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    }
  },
  methods: {
    ...mapActions('products', ['getRelatedProduct']),
    ...mapActions('google', ['trackEvent']),
    selectImg: ($event) => {
      const imgs = [...document.querySelectorAll('.product__meta__images .product__meta__images__wrapper')]
      imgs.map(img => {
        img.classList.remove('selected')
      })
      $event.target.classList.add('selected')
    },
    selectLocation: function () {
      if (!this.$route.meta.hideLocation) {
        this.$store.dispatch('changeStateLModal', true);
        document.querySelector('html').style.overflow = "hidden"
        document.querySelector('body').style.overflow = "hidden"
      }
    },
    getLowerPrice: function (product) {
      let lowerPrice = 0.0
      let result;
      const prices = this.$store.getters["pricelist/getPricelistItems"]

      if (prices.length !== 0 && product) {
        product.productproduct_set.forEach(e => {
          let item = prices.find(i => {
            return i.product.id === e.id;
          })
          if (lowerPrice === 0 || lowerPrice > item.price_it) {
            lowerPrice = item.price_it;
            result = e
          }
        })
        return result;
      } else {
        return result;
      }
    }
    ,
    getCategoryName: function () {
      let c;
      this.product.categs.forEach(e => {
        switch (e.name) {
          case "Huîtres":
            c = 1
            break;
          case "Crustacés":
            c = 2
            break;
          case "Coquillages":
            c = 3
            break;
          case "Epicerie":
            c = 4
            break;
          case "Cave":
            c = 5
            break;
          case "Coffrets":
            c = 6
            break;
          case "Accessoires":
            c = 7
            break;
        }
      })
      this.$store.commit('category/setCategory', parseInt(c))
    }
    ,
    getProductById: async function () {
      await getAPI
          .get('/product/template/' + this.id + '/')
          .then(response => {
            this.product = response.data.data;
            this.$meta().refresh()
            let lower = this.getLowerPrice(this.product)
            if (!this.getCategory) {
              this.getCategoryName()
            }
            this.form.selected = []
            this.product.attribute.forEach(a => {
              this.form.selected.push(lower.attribute_ids.find(b => b.attribute_id === a.res_id).id)
            })
            const eventParams = {items: [this.product]}
            this.trackEvent({action: 'select_item', eventParams: eventParams})
          })
          .catch(errors => {
            console.log(errors);
          })
      await this.getRelatedProduct({'prices': this.getPricelistItems, 'productId': this.id})
    }
    ,
    changeNext: function (index) {
      const nextIndex = index + 1
      if (this.product.attribute[nextIndex]) {
        this.cleanSelects(nextIndex)
        let nextAttributeId = this.product.attribute[nextIndex].res_id
        let result = this.compareList(nextAttributeId, nextIndex)
        result = result.sort((a, b) => {
          return a.sequence > b.sequence ? 1 : -1
        })
        let nextSelect = document.getElementById('attribute-' + nextIndex)
        for (let i = 0; i < result.length; i++) {
          if (i === 0) {
            nextSelect.options[i] = new Option(result[i].text, result[i].value, true, true)
          } else {
            nextSelect.options[i] = new Option(result[i].text, result[i].value)
          }
        }
      }
    }
    ,
    compareList: function (nextAttributeId, indexing) {
      const cond1 = nextAttributeId !== undefined
      const cond2 = indexing !== undefined
      let combos = [];
      let result = [];
      this.form.selected.forEach((e, index) => {
        if (cond1 && cond2) {
          if (index < indexing) {
            combos.push({
              'id': e,
              'attribute_id': this.product.attribute[index].res_id,
            })
          }
        } else {
          combos.push({
            'id': e,
            'attribute_id': this.product.attribute[index].res_id,
          })
        }
      })
      this.product.productproduct_set.forEach((p) => {
        if (Utils.isPresent(combos, p.attribute_ids)) {
          if (nextAttributeId !== undefined) {
            const n = p.attribute_ids.find(a => a.attribute_id === nextAttributeId)
            const text = this.product.attribute_value.find(a => a.res_id === n.id)
            const option = {'text': text.value, 'value': text.res_id, 'sequence': n.sequence}
            if (!result.some(r => r.value === option.value)) result.push(option)
          } else {
            result.push(p)
          }
        }
      })
      return result;
    }
    ,
    cleanSelects: function (index) {
      if (index <= this.product.attribute.length - 1) {
        for (let i = index; i < this.product.attribute.length; i++) {
          let select = document.getElementById('attribute-' + String(i))
          select.innerHTML = null
        }
      }
    }
    ,
    getAttributeValue: function (attribute_id) {
      let values = [];

      this.product.productproduct_set.forEach(e => {
        e.attribute_ids.forEach(i => {
          if (i.attribute_id === attribute_id) {
            let attribute_value = this.product.attribute_value.find(e => e.res_id === i.id)
            const attr = {
              'id': i.id,
              'value': attribute_value.value,
              'sequence': i.sequence
            }
            if (!values.filter(e => e.id === attr.id).length > 0) {
              values.push(attr)
            }
          }
        });
      });
      return values.sort((a, b) => {
        return a.sequence > b.sequence ? 1 : -1
      })
    }
    ,
    addToCart: function (event) {
      const form = event.target.elements;
      let variant = [];

      for (let element of form) {
        if (element.tagName === 'SELECT') {
          variant.push(parseInt(element.value))
        }
      }

      let p = this.product.productproduct_set.find(e => {
        return e.id === this.variant
      })
      p['name'] = this.product.name;
      p['image'] = this.product.image;
      p['categ'] = this.getCategory.name;

      if (this.quantity < 1 || isNaN(this.quantity)) {
        this.quantity = 1;
      }

      const item = {
        product: p,
        quantity: this.quantity,
        price_df: this.price.price_without_vat,
        price: this.price.price_it,
        weight: (p.weight * this.quantity).toFixed(2)
      }
      this.$store.dispatch('cart/addToCart', item);
      this.$toast.open({
        message: i18n.t('add_cart_success'),
        type: 'success',
      });
    }
  }
  ,
  watch: {
    id(newValue) {
      this.quantity = newValue === 'couteau' ? 3 : 1;
    },
    '$route.params':
        {
          handler() {
            this.getProductById();
          }
          ,
          immediate: true,
        },
    'getLocation':
        {
          handler() {
            if (this.getLocation) {
              this.getProductById();
            }
          }
          ,
          immediate: true,
        }
  }
  ,
  getLocation: function () {
    this.getProductById();
  }
}
</script>

<style lang="scss" scoped>
@use '@/scss/variables' as *;



main {
  @include flex(flex-start, flex-start, column);

  .spinner_container {
    @include flex($justify: center, $align: center, $direction: row, $wrap: nowrap, $gap: 0);
    position: relative;
    gap: 7.5%;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}



.product__wrapper {
  @include flex(flex-start, flex-start, column, $gap: 100px);
  width: 100%;
  padding: 0 .75rem;

  @media screen and (min-width: 568px) {
    padding: 2vh 4%;
  }

  .section-title {
    @include flex(flex-start, flex-start, column);
    margin-bottom: 35px;
    @media screen and (max-width: 991.99px) {
      justify-content: center;
      text-align: left;
      gap: 10px;
    }

    h2 {
      font-weight: bold;
      letter-spacing: 2px;
      word-spacing: 2px;
      font-size: 2.2rem;
      width: 100%;
      @media screen and (max-width: 991.99px) {
        font-size: 1.4rem;
      }
    }

    p {
      color: $Grey;
      width: 100%;
      text-align: left;
      @media screen and (max-width: 991.99px) {
        font-size: 14px;
      }
    }
  }

  // Section
  .product {
    @include flex(space-between, flex-start, $gap: 60px);
    padding: 50px 0;
    width: 100%;

    &__meta {
      @include flex(space-between, flex-start, $gap: 20px);
      width: 100%;

      @media screen and (max-width: 767.99px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      @media screen and (min-width: 768px) and (max-width: 991.99px) {
        flex-wrap: wrap;
      }

      &__images {
        @include flex(flex-start, initial, row, wrap, $gap: 5px);
        flex: 0 0 27%;
        max-height: 70vh;
        pointer-events: none;

        @media screen and (min-width: 768px) and (max-width: 991.99px) {
          flex: 0 0 100%;
        }

        @media screen and (max-width: 991.99px) {
          width: 100%;
          order: 1;
          max-height: 100%;
          @include flex(flex-start, flex-start);
          overflow-x: auto;
          scroll-behavior: smooth;
          scroll-snap-type: x mandatory;
          pointer-events: all;
          @include is-firefox() {
            scrollbar-width: thin;
            scrollbar-color: $t $t;
          }
          @media screen and (max-width: 575.99px) {
            $gap: 0;
            gap: $gap;
          }
          @media screen and (min-width: 576px) and (max-width: 991.99px) {
            $gap: 19.99px;
            gap: $gap;
          }

          &::-webkit-scrollbar {
            height: 0;
          }

        }

        &__wrapper {
          @include flex(center, center);
          pointer-events: all;
          aspect-ratio: 1;
          transition: .2s opacity;
          flex: 0 0 calc(33% - 10px / 3);

          &:not(.selected) {
            max-width: 100%;
          }

          &.selected {
            order: 1 !important;
            flex: 0 0 100% !important;
          }

          &:nth-child(1) {
            order: 2;
          }

          &:nth-child(2) {
            order: 3;
          }

          &:nth-child(3) {
            order: 4;
          }

          &:nth-child(4) {
            order: 5;
          }

          &:nth-child(1n + 5) {
            display: none;
          }

          @media screen and (max-width: 575.99px) {
            scroll-snap-align: center;
            $number: 1;
            $gap: 0px;
            @include flex-child($number, $gap);
          }

          @media screen and (min-width: 576px) and (max-width: 991.99px) {
            $number: 2;
            $gap: 20px;
            scroll-snap-align: start;
            @include flex-child($number, $gap);
          }

          //@media screen and (min-width: 768px) and (max-width: 991.99px){
          //	$number: 3;
          //	$gap: 20px;
          //	scroll-snap-align: start;
          //	@include flex-child($number, $gap);
          //}

          @media screen and (min-width: 992px) {
            &:not(.selected) {
              cursor: pointer;

              &:hover {
                opacity: .7;
              }
            }
          }
        }

        img {
          border-radius: 10px;
          max-height: 100%;
          user-select: auto;
          -webkit-user-drag: none;
          -khtml-user-drag: none;
          -moz-user-drag: none;
          -o-user-drag: none;
          user-drag: none;

          @media screen and (max-width: 991.99px) {
            max-height: 300px;
          }

          @media screen and (min-width: 992px) {
            width: 100%;
            object-fit: cover;
          }
        }
      }

      &__text {
        @include flex(flex-start, flex-start, column);
        flex: 1;
        @media screen and (max-width: 991.99px) {
          order: 3;
          margin-top: 20px;
        }
        @media screen and (min-width: 992px) {
          padding-right: 3vw;
        }
        @media screen and (min-width: 768px) and (max-width: 991.99px) {
          flex: 0 0 calc(50% - 10px);
          order: 2
        }

        &-tags {
          @include flex(flex-start, center, $gap: 5px);

          li {
            position: relative;
            color: $Grey;

            &:not(:last-child)::after {
              content: ' - ';
            }
          }
        }

        &-description {
          @include flex(flex-start, flex-start, column);
          text-align: justify;

          h3 {
            margin: 10px 0 5px;
            width: 100%;
          }

          p {
            color: $Grey;
          }
        }
      }

      &__form {
        @include flex(flex-start, flex-start, $gap: 20px);
        flex: 0 0 25%;
        width: 100%;
        @media screen and (max-width: 991.99px) {
          order: 2
        }
        @media screen and (min-width: 768px) and (max-width: 991.99px) {
          flex: 0 0 calc(50% - 10px);
          order: 3
        }

        .cart-form {
          @include flex(flex-start, flex-start, column, $gap: 20px);
          width: 100%;

          &__row {
            @include flex(flex-start, flex-start, column, $gap: 5px);
            width: 100%;

            label {
              color: $Blue;
              font-weight: 600;
              text-transform: uppercase;
            }

            .is-available {
              font-weight: 600;
              letter-spacing: 2px;

              .available {
                color: #78C85C;
              }

              .not-available {
                color: #C85C5C;
              }
            }

            .select-wrapper {
              @include flex(space-between, center);
              position: relative;
              width: 100%;

              span {
                @include position(52.5%, $right: 7.5px);
                display: block;
                width: 15px;
                height: 15px;
                translate: 0 -50%;
                pointer-events: none;
                background-color: $LightBrown;
                clip-path: polygon(50% 75%, 0 25%, 100% 25%);
              }

              select {
                appearance: none;
                background-color: transparent;
                border: solid 1px $LightBrown;
                margin: 0;
                width: 100%;
                padding: .5rem calc(.75rem + 20px) .5rem .75rem;
                outline: none;
                border-radius: 5px;
                font-size: 15px;

                &::-ms-expand {
                  display: none;
                }

                @include default-focus();

                option {
                  background-color: $White;
                  color: $Black;

                  &:checked {
                    background-color: $LightBrown;
                    color: white;
                  }

                  &:disabled {
                    background-color: #cccccc;
                    cursor: no-drop;
                  }
                }
              }
            }

            .quantity {
              @include flex(center, center, $gap: 10px);
              text-align: center;

              input {
                appearance: none;
                min-width: 3ch;
                border: none;
                text-align: center;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                @include is-firefox() {
                  -moz-appearance: textfield
                }
              }

              button {
                @include flex(center, center);
                appearance: none;
                border: solid 1px $Brown;
                background-color: $t;
                width: 20px;
                height: 20px;
                line-height: 1;
                transition: .2s;
                border-radius: 20px;

                svg path {
                  fill: $Brown;
                  transition: .2s;
                }

                &:hover, &:focus {
                  background-color: $Brown;

                  svg path {
                    fill: $White;
                  }
                }
              }
            }

            .cart-resume {
              width: 100%;
              outline: 1px solid $Brown;
              outline-offset: -1px;
              border-radius: 5px;

              .total-price {
                padding: 10px;
                font-weight: 600;
                font-size: 22px;
                color: $Black;
                text-align: center;
                letter-spacing: 2px;
                white-space: nowrap;
              }

              button {
                padding: 10px 5px;
                border: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;

                &:hover {
                  background-color: $Brown;
                  color: $White;
                  opacity: .7;
                }
              }
            }
          }
        }
      }
    }
  }

  // Section
  .related-products {
    @include flex(flex-start, flex-start, column);
    width: 100%;
  }

  // Section
  .product-details, .recipe {
    @include flex(flex-start, flex-start, column);
    width: 100%;

    &__wrapper {
      @include flex(space-between, flex-start, column, $gap: 20px);
      width: 100%;

      @media screen and (min-width: 992px) {
        gap: 100px
      }
      @media screen and (min-width: 768px) {
        flex-direction: row;
      }

    }

    &__text {
      @include flex(flex-start, flex-start, column, $gap: 20px);
      width: 100%;

      &__row {
        @include flex(flex-start, flex-start, column, $gap: 10px);
        width: 100%;
        @media screen and (max-width: 767.99px) {
          align-items: center;
        }
      }

      @media screen and (max-width: 767.99px) {
        h3 {
          width: 100%;
          text-align: center;
        }
      }

      ul {
        @include flex(flex-start, flex-start, column);

        li {
          // @include flex(flex-start, flex-start, $gap: 5px);
          display: inline;
          margin: 5px 0;
          position: relative;

          &::before {
            content: '';
            @include fixed-size(5px);
            background-color: $Brown;
            align-self: center;
            border-radius: 10px;
          }

          h3 {
            font-weight: 700;
            display: inline;
            font-size: 16px;
          }

          span {
            font-size: 16px;
          }
        }
      }

      ol {
        counter-reset: item;

        li {
          counter-increment: item;

          &::before {
            display: inline;
            width: 1em;
            font-weight: bold;
            text-align: right;
            content: counter(item) ".";
          }
        }
      }
    }

    &__img {
      @include flex(center, center);
      width: 100%;

      img {
        border-radius: 15px;
        max-width: 100%;
        max-height: 250px;
        object-fit: cover;
        @media screen and (max-width: 575.99px) {
          width: 100%
        }
      }
    }

  }

  // Section
  .custom-details {
    width: 100%;

    &__text {
      width: 100%;

      p {
        text-align: left;
        color: $Grey;
      }

      a {
        color: $Blue;
      }
    }
  }

  // Section
  .icons {
    width: 100%;

    &__wrapper {
      @include flex(space-between, center, row, wrap, $gap: 5vw);
      width: 100%;
      @media screen and (max-width: 578.99px) {
        flex-direction: column;
        gap: 50px
      }

      .icon {
        @include flex(center, center, column);
        text-align: center;
        flex: 1;
        @media screen and (max-width: 991.99px) {
          flex: 0 0 calc(50% - 2.5vw);
        }

        svg {
          @include fixed-size(70px)
        }

        h3 {
          margin-top: 10px;
        }

        p {
          color: $Grey;
          @media screen and (max-width: 578.99px) {
            width: 70%;
          }
        }
      }
    }
  }
}
</style>